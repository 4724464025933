import React from 'react'
import {  Link } from 'react-router-dom';
import Technology from './Technology';
function Aboutus() {
  return (
    <div>
    {/* page-title */}
<div className="prt-page-title-row">
  <div className="container">
    <div className="row">
      <div className="col-md-12"> 
        <div className="title-box text-center">
          <div className="page-title-heading">
            <h1 className="title">About Us</h1>
          </div>{/* /.page-title-captions */}
          <div className="breadcrumb-wrapper">
            <span>
              <a title="Homepage" href="/"><i className="ti ti-home" />Home</a>
            </span>
            <span className="prt-bread-sep">: :</span>
            <span>About Us</span>
          </div>  
        </div>
      </div>{/* /.col-md-12 */}  
    </div>{/* /.row */}  
  </div>{/* /.container */}                      
</div>{/* page-title end*/}
{/*site-main start*/}
<div className="site-main">
  {/* aboutus2-section start */}
  <section className="prt-row aboutus2-section clearfix">
    <div className="container">
      <div className="row">{/* row */}
        <div className="col-lg-6">
          {/* section title */}
          <div className="section-title2 with-desc clearfix">
            <div className="section-title">
              <div className="title-heading">
                <div className="sub-title">
                  <span>About Quadbrains</span>
                </div>
                <h2 className="title">Connecting things, It’s all About people</h2>
              </div>
            </div>
            <div className="title-desc">
              <p>QuadBrains Technology is a dynamic  startup company specializing in IT services.
 Our team comprises IT professionals with  over 10 years of experience in web
 development, app development, mobile app creation, and custom software
 solutions. We pride ourselves on delivering high-quality, tailored IT services that meet
 our clients' unique needs</p>
              <p>We offer a comprehensive suite of IT services, including:</p>
            </div>
          </div>{/* section title end */}
          {/* row */}
          <div className="row no-gutters mt-35 mb-25">
            <div className="col-md-6 col-lg-6 col-sm-6">
              <ul className="prt-list prt-list-style-icon">
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">24/7 Hours Support  </span></li>
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">Flexible Price</span></li>
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">Web And Mobile Application </span></li>
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">Website Design and Enhancement</span></li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-6">
              <ul className="prt-list prt-list-style-icon">
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">Custom Software Development </span></li>
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">Data Analytics</span></li>
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">Data Engineering</span></li>
                <li><i className="fa fa-check-square" /><span className="prt-list-li-content">IT Consulting</span></li>
              </ul>
            </div>
          </div>{/* row END*/}
          <p className="mb-30">High-tech industries are crucial to today’s economy, offering competitive pay and driving innovation. At QuadBrains Technology, we are dedicated to leading in digital solutions and web applications, ensuring our clients benefit from the latest advancements.

</p>
          <Link to="/contact-us" className="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border" >Contact Us</Link>
        </div>
        <div className="col-lg-6">
          <div className="position-relative res-991-mt-30">
            {/* ttm_single_image-wrapper */}
            <div className="ttm_single_image-wrapper">
              <img className="img-fluid" src="assets/images/single-img-seven.html" alt="single-img-seven" />
            </div>{/* ttm_single_image-wrapper end */}
          </div>
        </div>
      </div>
      {/* row end */}
    </div>
  </section>
  {/* aboutus2-section end */}
 {/*client-section*/}
 <Technology />
{/*client-section end*/}

</div>

</div>

  )
}

export default Aboutus

import React from 'react';
import {  Link } from 'react-router-dom';

// import slid1 from 'revolution/assets/slider-01-1-50x100.webp'

function Revolution() {
  return (
<div>
  {/* revolution start */}
  <p className="rs-p-wp-fix" />
  <rs-module-wrap id="rev_slider_1_1_wrapper" data-source="gallery" style={{visibility: 'hidden', background: 'transparent', padding: 0, margin: '0px auto', marginTop: 0, marginBottom: 0}}>
    <rs-module id="rev_slider_1_1" style={{}} data-version="6.5.7">
      <rs-slides>
        <rs-slide style={{position: 'absolute'}} data-key="rs-1" data-title="Slide" data-thumb="/assets/revolution/assets/slider-01-1-50x100.webp" data-in="o:0;" data-out="a:false;">
          <img src="/assets/revolution/assets/dummy.html" title="slider-01-1.webp" width={1920} height={682} className="rev-slidebg tp-rs-img rs-lazyload" data-lazyload="/assets/revolution/assets/slider-01-1.webp" data-no-retina />
          {/*
                    */}<rs-layer id="slider-1-slide-1-layer-0" data-type="shape" data-rsp_ch="on" data-xy="x:c;y:m;" data-text="w:normal;s:20,20,12,7;l:0,0,15,9;" data-dim="w:5000px,5000px,3137px,1935px;h:682px,682px,682px,420px;" data-frame_999="o:0;st:w;" style={{zIndex: 8, backgroundColor: 'rgba(26,26,26,0.6)'}}> 
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-1-layer-1" data-type="text" data-color="#ff5538" data-rsp_ch="on" data-xy="x:l,l,c,c;xo:73px,73px,33px,20px;yo:139px,139px,99px,68px;" data-text="w:normal;s:17,17,16,16;l:28,28,26,26;fw:700;" data-frame_0="x:-50,-50,-31,-19;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;" style={{zIndex: 9, fontFamily: '"Rajdhani"'}}>Welcome to Quadbrains Technologies
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-1-layer-2" data-type="text" data-rsp_ch="on" data-xy="x:l,l,c,c;xo:50px,50px,0,0;yo:160px,160px,151px,111px;" data-text="w:normal;s:63,63,64,48;l:78,78,48,58;fw:700;" data-frame_0="x:-50,-50,-31,-19;" data-frame_1="sp:1100;" data-frame_999="o:0;st:w;" style={{zIndex: 11, fontFamily: '"Rajdhani"'}}>Advanced Innovation<br />
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-1-layer-3" data-type="text" data-rsp_ch="on" data-xy="x:l,l,c,c;xo:50px,50px,0,0;y:m;yo:-20px,-20px,20px,24px;" data-text="w:normal;s:63,63,64,48;l:73,73,48,58;fw:700;" data-frame_0="x:-50,-50,-31,-19;" data-frame_1="sp:1200;" data-frame_999="o:0;st:w;" style={{zIndex: 10, fontFamily: '"Rajdhani"'}}>IT Solutions 
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-1-layer-4" data-type="text" data-rsp_ch="on" data-xy="xo:80px,80px,63px,46px;y:m;yo:52px,52px,58px,43px;" data-text="w:normal;s:13,13,7,4;l:24,24,14,8;" data-vbility="t,t,f,f" data-frame_0="x:-50,-50,-31,-19;" data-frame_1="sp:1300;" data-frame_999="o:0;st:w;" style={{zIndex: 12, fontFamily: '"Rubik"'}}>The Age Of Technology Has Arrived<br/> Where Technology Innovation Meets A Culture Of Excellence.  
          </rs-layer>{/*

                    */}<Link to="/services/web-development" id="slider-1-slide-1-layer-5" className="rs-layer" href="aboutus.html" target="_self" data-type="text" data-rsp_ch="on" data-xy="x:l,l,c,c;xo:50px,50px,-100px,0;y:m;yo:138px,138px,120px,105px;" data-text="w:normal;s:14,14,15,15;l:28,28,17,10;fw:700;" data-padding="t:7,7,10,10;r:35;b:5,5,10,10;l:35;" data-border="bor:6px,6px,6px,6px;" data-frame_0="y:50,50,31,19;" data-frame_1="sp:1400;" data-frame_999="o:0;st:w;" data-frame_hover="bgc:#1a1a1a;bor:6px,6px,6px,6px;" style={{zIndex: 14, backgroundColor: '#ff5538', fontFamily: '"Rajdhani"', textTransform: 'uppercase'}}>read more 
          </Link>{/*

                    */}<Link to="contact-us" id="slider-1-slide-1-layer-6" className="rs-layer" target="_self" data-type="text" data-rsp_ch="on" data-xy="x:l,l,c,c;xo:216px,216px,64px,39px;y:m;yo:138px,138px,120px,105px;" data-text="w:normal;s:14,14,15,9;l:28,28,17,10;fw:700;" data-vbility="t,t,t,f" data-padding="t:7,7,10,6;r:35,35,35,22;b:5,5,10,6;l:35,35,35,22;" data-border="bos:solid;boc:#ffffff;bow:1px,1px,1px,1px;bor:6px,6px,6px,6px;" data-frame_0="y:50,50,31,19;" data-frame_1="sp:1400;" data-frame_999="o:0;st:w;" data-frame_hover="c:#1a1a1a;bgc:#fff;boc:#fff;bor:6px,6px,6px,6px;bos:solid;bow:1px,1px,1px,1px;" style={{zIndex: 13, backgroundColor: 'rgba(13,209,184,0)', fontFamily: '"Rajdhani"', textTransform: 'uppercase'}}>contact us 
          </Link>{/*

                    */}
                    <rs-layer id="slider-1-slide-1-layer-7" data-type="image" data-rsp_ch="on" data-xy="x:l,l,c,c;xo:50px,50px,-61px,-76px;yo:139px,139px,105px,65px;" data-text="w:normal;s:20,20,12,7;l:0,0,15,9;" data-dim="w:14px,14px,8px,14px;h:21px,21px,12px,21px;" data-frame_0="x:-50,-50,-31,-19;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;" style={{ zIndex: 15,   filter: "sepia(135%) saturate(254%) hue-rotate(-399deg)"}}><img src="/assets/revolution/assets/dummy.html" alt="sep" className="tp-rs-img rs-lazyload" width={14} height={21} data-lazyload="/assets/revolution/assets/head-sep.webp" data-no-retina /> 
          </rs-layer>
          
          {/*

                    */}<rs-layer id="slider-1-slide-1-layer-8" data-type="shape" data-rsp_ch="on" data-xy="xo:50px,50px,31px,19px;y:m;yo:57px,57px,57px,42px;" data-text="w:normal;s:20,20,12,7;l:0,0,15,9;" data-dim="w:4px,4px,2px,1px;h:45px,45px,28px,17px;" data-vbility="t,t,f,f" data-frame_0="x:-50,-50,-31,-19;" data-frame_1="sp:1300;" data-frame_999="o:0;st:w;" style={{zIndex: 16, backgroundColor: '#ff5538'}}> 
          </rs-layer>{/*
*/}                   </rs-slide>
        <rs-slide style={{position: 'absolute'}} data-key="rs-2" data-title="Slide" data-thumb="/assets/revolution/assets/slider-02-50x100.webp" data-in="o:0;" data-out="a:false;">
          <img src="./assets/revolution/assets/dummy.html" title="slider-02.webp" width={1920} height={682} className="rev-slidebg tp-rs-img rs-lazyload" data-lazyload="/assets/revolution/assets/slider-02.webp" data-no-retina />
          {/*
                    */}<rs-layer id="slider-1-slide-2-layer-0" data-type="shape" data-rsp_ch="on" data-xy="x:c;y:m;" data-text="w:normal;s:20,20,12,7;l:0,0,15,9;" data-dim="w:1920px,1920px,1204px,742px;h:682px,682px,682px,420px;" data-frame_999="o:0;st:w;" style={{zIndex: 8, backgroundColor: 'rgba(26,26,26,0.54)'}}> 
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-2-layer-1" data-type="text" data-color="#ff5538" data-rsp_ch="on" data-xy="x:r,r,c,c;xo:50px,50px,33px,20px;yo:129px,129px,99px,68px;" data-text="w:normal;s:17,17,16,16;l:28,28,26,26;fw:700;" data-frame_0="x:50,50,31,19;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;" style={{zIndex: 9, fontFamily: '"Rajdhani"'}}>Best IT Solution Provider 
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-2-layer-2" data-type="text" data-rsp_ch="on" data-xy="x:r,r,c,c;xo:50px,50px,0,0;yo:164px,164px,151px,111px;" data-text="w:normal;s:63,63,64,40;l:78,78,74,58;fw:700;a:left,left,left,center;" data-frame_0="x:50,50,31,19;" data-frame_1="sp:1100;" data-frame_999="o:0;st:w;" style={{zIndex: 11, fontFamily: '"Rajdhani"'}}>Taking you places you’ve 
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-2-layer-3" data-type="text" data-rsp_ch="on" data-xy="x:r,r,c,c;xo:50px,50px,0,-2px;y:m;yo:-8px,-8px,20px,23px;" data-text="w:normal;s:63,63,64,40;l:73,73,74,50;fw:700;a:left,left,left,center;" data-frame_0="x:50,50,31,19;" data-frame_1="sp:1200;" data-frame_999="o:0;st:w;" style={{zIndex: 10, fontFamily: '"Rajdhani"'}}>never been before 
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-2-layer-4" data-type="text" data-rsp_ch="on" data-xy="x:r;xo:70px,70px,43px,26px;y:m;yo:80px,80px,67px,52px;" data-text="w:normal;s:14,14,8,4;l:24,24,14,8;a:right;" data-vbility="t,t,f,f" data-frame_0="x:50,50,31,19;" data-frame_1="sp:1300;" data-frame_999="o:0;st:w;" style={{zIndex: 12, fontFamily: '"Rubik"'}}>We are equipped with an updated technical knowledge to serve our customers properly.<br /> Our method of application maintains the industry.  
          </rs-layer>{/*

                    */}<Link  to="/services/web-development" id="slider-1-slide-2-layer-5" className="rs-layer" target="_self" data-type="text" data-rsp_ch="on" data-xy="x:r,r,c,c;xo:222px,222px,-100px,0;y:m;yo:149px,149px,105px,105px;" data-text="w:normal;s:14,14,15,15;l:28,28,17,10;fw:700;" data-padding="t:7,7,10,10;r:35;b:5,5,10,10;l:35;" data-border="bor:6px,6px,6px,6px;" data-frame_0="y:50,50,31,19;" data-frame_1="sp:1400;" data-frame_999="o:0;st:w;" data-frame_hover="bgc:#1a1a1a;bor:6px,6px,6px,6px;" style={{zIndex: 14, backgroundColor: '#ff5538', fontFamily: '"Rajdhani"', textTransform: 'uppercase',marginLeft:'25px'}}>read more 
          </Link>{/*

                    */}<Link to="/contact-us" id="slider-1-slide-2-layer-6" className="rs-layer"  target="_self" data-type="text" data-rsp_ch="on" data-xy="x:r,r,c,c;xo:50px,50px,64px,39px;y:m;yo:149px,149px,107px,73px;" data-text="w:normal;s:14,14,15,9;l:28,28,17,10;fw:700;" data-vbility="t,t,t,f" data-padding="t:7,7,10,6;r:35,35,35,22;b:5,5,10,6;l:35,35,35,22;" data-border="bos:solid;boc:#ffffff;bow:1px,1px,1px,1px;bor:6px,6px,6px,6px;" data-frame_0="y:50,50,31,19;" data-frame_1="sp:1400;" data-frame_999="o:0;st:w;" data-frame_hover="c:#1a1a1a;bgc:#fff;boc:#fff;bor:6px,6px,6px,6px;bos:solid;bow:1px,1px,1px,1px;" style={{zIndex: 13, backgroundColor: 'rgba(13,209,184,0)', fontFamily: '"Rajdhani"', textTransform: 'uppercase'}}>contact us 
          </Link>{/*

                    */}<rs-layer style={{ zIndex: 15,   filter: "sepia(135%) saturate(254%) hue-rotate(-399deg)"}} id="slider-1-slide-2-layer-7" data-type="image" data-rsp_ch="on" data-xy="x:r,r,c,c;xo:232px,232px,-61px,-78px;yo:129px,129px,105px,66px;" data-text="w:normal;s:20,20,12,7;l:0,0,15,9;" data-dim="w:14px,14px,8px,14px;h:21px,21px,12px,21px;" data-frame_0="x:50,50,31,19;" data-frame_1="sp:1000;" data-frame_999="o:0;st:w;" ><img src="/assets/revolution/assets/dummy.html" alt="sep" className="tp-rs-img rs-lazyload" width={14} height={21} data-lazyload="/assets/revolution/assets/head-sep.webp" data-no-retina /> 
          </rs-layer>{/*

                    */}<rs-layer id="slider-1-slide-2-layer-8" data-type="shape" data-rsp_ch="on" data-xy="x:r;xo:50px,50px,31px,19px;y:m;yo:80px,80px,57px,42px;" data-text="w:normal;s:20,20,12,7;l:0,0,15,9;" data-dim="w:4px,4px,2px,1px;h:45px,45px,28px,17px;" data-vbility="t,t,f,f" data-frame_0="x:50,50,31,19;" data-frame_1="sp:1300;" data-frame_999="o:0;st:w;" style={{zIndex: 16, backgroundColor: '#ff5538'}}> 
          </rs-layer>{/*
*/}                   </rs-slide>
      </rs-slides>
    </rs-module>
  </rs-module-wrap>
  {/* END REVOLUTION SLIDER */}
  {/* revolution end */}
</div>

  )
}

export default Revolution

import React from 'react'

function Technology() {
  return (
    <div>
      {/*client-section*/}
    <section className="prt-row prt-bg bg-base-skin client-section clearfix">
    <div className="container">
      {/* slick_slider */}
      <div className="row slick_slider spacing-3" data-slick="{&quot;slidesToShow&quot;: 6, &quot;slidesToScroll&quot;: 1, &quot;arrows&quot;:false, &quot;dots&quot;:false, &quot;autoplay&quot;:true, &quot;infinite&quot;:true, &quot;responsive&quot;: [{&quot;breakpoint&quot;:1170,&quot;settings&quot;:{&quot;slidesToShow&quot;: 5,&quot;arrows&quot;:false,&quot;autoplay&quot;:true}}, {&quot;breakpoint&quot;:840,&quot;settings&quot;:{&quot;slidesToShow&quot;: 4,&quot;arrows&quot;:false}}, {&quot;breakpoint&quot;:630,&quot;settings&quot;:{&quot;slidesToShow&quot;: 3}},{&quot;breakpoint&quot;:440,&quot;settings&quot;:{&quot;slidesToShow&quot;: 2}}]}">
        <div className="col-lg-2">
          <div className="client-box">
            <div className="client-thumbnail">
              <img src="/assets/images/technology/csharp1.png" loading="lazy" alt="client-logo" className="client-box-image img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="client-box">
            <div className="client-thumbnail">
              <img src="/assets/images/technology/java1.png" loading="lazy" alt="client-logo" className="client-box-image img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="client-box">
            <div className="client-thumbnail">
              <img src="/assets/images/technology/php1.png" loading="lazy" alt="client-logo" className="client-box-image img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="client-box">
            <div className="client-thumbnail">
              <img src="/assets/images/technology/react1.png" loading="lazy" alt="client-logo" className="client-box-image img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="client-box">
            <div className="client-thumbnail">
              <img src="/assets/images/technology/csharp1.png" loading="lazy" alt="client-logo" className="client-box-image img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="client-box">
            <div className="client-thumbnail">
              <img src="/assets/images/technology/ios1.png" loading="lazy" alt="client-logo" className="client-box-image img-fluid" />
            </div>
          </div>
        </div> 
        <div className="col-lg-2">
          <div className="client-box">
            <div className="client-thumbnail">
              <img src="/assets/images/technology/Wordpress1.png" loading="lazy" alt="client-logo" className="client-box-image img-fluid" />
            </div>
          </div>
        </div> 

                            
      </div>
    </div>
  </section>
  {/*client-section end*/}
    </div>
  )
}

export default Technology

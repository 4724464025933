import React from 'react'
import ServiceAside from './ServiceAside';

function DataEngineering() {
  return (
    <div>
  {/* page-title */}
  <div className="prt-page-title-row">
    <div className="container">
      <div className="row">
        <div className="col-md-12"> 
          <div className="title-box text-center">
            <div className="page-title-heading">
              <h1 className="title">Data Engineering</h1>
            </div>{/* /.page-title-captions */}
            <div className="breadcrumb-wrapper">
              <span>
                <a title="Homepage" href="/"><i className="ti ti-home" />Home</a>
              </span>
              <span className="prt-bread-sep">: :</span>
              <span>Data Engineering</span>
            </div>  
          </div>
        </div>{/* /.col-md-12 */}  
      </div>{/* /.row */}  
    </div>{/* /.container */}                      
  </div>{/* page-title end*/}
  {/*site-main start*/}
  <div className="site-main">
    {/* sidebar */}
    <div className="sidebar prt-sidebar prt-bgcolor-white clearfix">
      <div className="container">
        {/* row */}
        <div className="row">
          <div className="col-lg-8 content-area order-lg-2 pt-65">
            {/* prt-service-single-content-are */}
            {/* prt-service-single-content-are */}
            <div className="prt-service-single-content-area mr-15">
              {/* section title */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-heading">
                  <div className="sub-title">
                    <span>We Build everything</span>
                  </div>
                  <h2 className="title">Data Engineering</h2>
                </div>
              </div>{/* section title end */}
              <div className="ttm_single_image-wrapper mb-35">
              <img className="img-fluid" src="/assets/images/single-img-fourteen.html" alt="single-img-fourteen" />
              </div>
              <div className="prt-service-description">
               
                <p>At QuadBrains Technology, we understand the critical role data plays in modern businesses. Our data engineering services are designed to help organizations efficiently capture, store, and process vast amounts of data, transforming raw information into actionable insights that drive informed decision-making.</p>
              
               {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                </div>
                {/* separator */}
                <div className="row">
  <div className="col-md-12">
    <h3>Key Features of Data Engineering</h3>

{/* accordion */}
<div className="accordion style2 mt-20 mb-70">
  {/* Key Data Engineering Services */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventy">Data Pipeline Development</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            We build robust and scalable data pipelines that automate the extraction, transformation, and loading (ETL) of data from multiple sources into your data warehouse or data lake. Our pipelines ensure data is always available, clean, and ready for analysis.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Data Warehouse Design and Implementation */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventyOne">Data Warehouse Design and Implementation</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            We design and implement modern data warehouses tailored to your business needs. Whether you're leveraging cloud-based platforms like AWS Redshift, Google BigQuery, or on-premises solutions, we ensure your data warehouse is optimized for performance, scalability, and cost-efficiency.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Real-time Data Processing */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventyTwo">Real-time Data Processing</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            Our real-time data processing services allow businesses to react to data as it is generated. Using tools like Apache Kafka, Apache Flink, and Spark Streaming, we build systems that handle large volumes of data in real-time, enabling real-time analytics and decision-making.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Data Integration and Migration */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventyThree">Data Integration and Migration</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            We specialize in integrating data from diverse sources, ensuring seamless migration from legacy systems to modern cloud infrastructures or new data environments. Our goal is to minimize disruption and ensure data integrity throughout the process.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Data Governance and Quality Management */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventyFour">Data Governance and Quality Management</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            To ensure high-quality, trusted data, we implement data governance frameworks that enforce rules, policies, and procedures. We establish data quality metrics and continuously monitor data for accuracy, completeness, and consistency.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Big Data Solutions */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventyFive">Big Data Solutions</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            We leverage big data technologies like Hadoop, Apache Spark, and NoSQL databases to help businesses process and analyze massive datasets. Our big data solutions are designed for scalability, enabling companies to handle ever-growing volumes of data.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Cloud Data Engineering */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventySix">Cloud Data Engineering</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            Our cloud data engineering services focus on using platforms like AWS, Google Cloud, and Microsoft Azure to design, build, and manage data infrastructure in the cloud. We optimize cloud resources to reduce costs while maintaining high performance and reliability.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Data Security and Compliance */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventySeven">Data Security and Compliance</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            We ensure your data infrastructure is secure and compliant with industry regulations such as GDPR, HIPAA, and CCPA. Our data engineering practices include encryption, access control, and continuous monitoring to safeguard sensitive data.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Machine Learning Data Preparation */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventyEight">Machine Learning Data Preparation</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            We help organizations prepare their data for machine learning by cleaning, transforming, and labeling datasets. By organizing and structuring data properly, we enable data scientists to build more accurate and effective models.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}

  {/* Analytics and Business Intelligence (BI) Enablement */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSeventyNine">Analytics and Business Intelligence (BI) Enablement</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">
            Our data engineering services support the implementation of BI tools and analytics platforms. We ensure your data is ready for reporting, dashboarding, and analysis, empowering stakeholders to make data-driven decisions.
          </p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
</div>{/* accordion end */}

   
  </div>
</div>

              </div>
            </div>
            {/* prt-service-single-content-are end */}
          </div>
          <div className="col-lg-4 widget-area">
          <ServiceAside />
           
            
          </div>
        </div>{/* row end */}
      </div>
    </div>
    {/* sidebar end */}
  </div>{/*site-main end*/}
</div>

  )
}

export default DataEngineering

import React from 'react'
import Revolution from './Revolution'
import {  Link } from 'react-router-dom';
import Technology from './Technology';
function Home() {
  return (
    <div>
      <Revolution />
      {/* aboutus-section start */}
  <section className="prt-row aboutus-section clearfix">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-12">
          <div className="section-title">
            <div className="title-heading">
              <div className="sub-title">
                <span>about us</span>
              </div>
              <h2 className="title">We are here to provide IT Solution</h2>
            </div>
          </div>
          <div className="aboutus-desc mb-35">
            <p>QuadBrains Technology is a dynamic
startup company specializing in IT services.
Our team comprises IT professionals with
over 10 years of experience in web
development, app development, mobile
app creation, and custom software
solutions. We pride ourselves on delivering
high-quality, tailored IT services that meet
our clients' unique needs.</p>
          </div>
          <div className="aboutus-btn res-991-mb-30">
            <Link to="/about-us" className="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border" >Read More</Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="prt-single-image-wrapper">
            <img className="img-fluid lazyloaded" data-src="/assets/images/single-img-one.webp" alt="single-img-one" src="/assets/images/single-img-01.html" />
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="featured-detail">
            <h3 className="featured-title-h3 mt-30">Awaited  IT support &amp; solutions for your business</h3>
            <p>Our firm is expert to create an efficient user interface that makes user interaction is lively and run digital applications. We design, together.</p>
            <div className="separator">
              <div className="sep-line mt-35 mb-35 res-991-mb-20 res-375-mt-0 res-375-mb-0" />
            </div>
            <div className="row">
              <div className="col-lg-6 res-375-pt-15">
                {/*featured-icon-box*/}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="featured-icon">
                    <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-whitecolor prt-icon_element-size-lg">
                      <i className="flaticon flaticon-developer" />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h4>311</h4>
                      <p>Happy Client</p>
                    </div>
                  </div>
                </div>
                {/*featured-icon-box end*/}
              </div>
              <div className="col-lg-6">
                {/*featured-icon-box*/}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="featured-icon">
                    <div className="prt-icon prt-icon_element-onlytxt prt-icon_element-color-whitecolor prt-icon_element-size-lg">
                      <i className="flaticon flaticon-code" />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h4>170</h4>
                      <p>CLoud Design</p>
                    </div>
                  </div>
                </div>
                {/*featured-icon-box end*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* aboutus-section end */}  
  
  {/* service-section start */}
  <section className="prt-row service-section clearfix bg-img1 bg-base-grey res-991-pt-0">
    <div className="container">
      <div className="row row-equal-height">
        <div className="col-lg-4 pt-65">
          <div className="section-title">
            <div className="title-heading">
              <div className="sub-title">
                <span>Our Services</span>
              </div>
              <h2 className="title">We deal with the aspects of professional IT Services</h2>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="featured-icon-box icon-align-top-content style2">
            <div className="featured-icon-box d-flex">
              <div className="bg_icon res-375-mb-15">
                <i className="flaticon flaticon-global" />
              </div>
              <div className="featured-title">
                {/* <h3>Helthcare Industry</h3> */}
                <h4>Web Development</h4>
              </div>
            </div>
            <div className="featured-content">
              <div className="featured-desc pb-20">
                <p>Our team creates dynamic, responsive websites that offer a seamless user experience across all devices.</p>
              </div>
              <Link className="ttm-btn btn-inline ttm-icon-btn-right ttm-btn-size-md ttm-btn-color-skincolor" to="/services/web-development">Read more<i className="icon-right" /></Link>
            </div>
            <div className="bottom-icon-01">
              <i className="flaticon flaticon-global" />
            </div>
          </div>  
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="featured-icon-box icon-align-top-content style2">
            <div className="featured-icon-box d-flex">
              <div className="bg_icon res-375-mb-15">
                <i className="flaticon flaticon-web" />
              </div>
              <div className="featured-title">
                {/* <h3>Technology</h3> */}
                <h4>Data Engineering</h4>
              </div>
            </div>
            <div className="featured-content">
              <div className="featured-desc mb-20">
                <p>We build robust data pipelines and architectures to transform your raw data into actionable insights.</p>
              </div>
              <Link className="ttm-btn btn-inline ttm-icon-btn-right ttm-btn-size-md ttm-btn-color-skincolor" to="/services/data-engineering">Read more<i className="icon-right" /></Link>
            </div>
            <div className="bottom-icon-01">
              <i className="flaticon flaticon-web" />
            </div>
          </div>  
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="featured-icon-box icon-align-top-content style2">
            <div className="featured-icon-box d-flex">
              <div className="bg_icon res-375-mb-15">
                <i className="flaticon flaticon-data" />
              </div>
              <div className="featured-title">
                {/* <h3>Technology</h3> */}
                <h4>Mobile App</h4>
              </div>
            </div>
            <div className="featured-content">
              <div className="featured-desc pb-20">
                <p>Our experts design feature-rich mobile apps tailored to provide exceptional user experiences on any platform.</p>
              </div>
              <Link className="ttm-btn btn-inline ttm-icon-btn-right ttm-btn-size-md ttm-btn-color-skincolor" to="/services/mobile-app">Read more<i className="icon-right" /></Link>
            </div>
            <div className="bottom-icon-01">
              <i className="flaticon flaticon-data" />
            </div>
          </div>  
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="featured-icon-box icon-align-top-content style2">
            <div className="featured-icon-box d-flex">
              <div className="bg_icon res-375-mb-15">
                <i className="flaticon flaticon-padlock" />
              </div>
              <div className="featured-title">
                {/* <h3>Cyber Security</h3> */}
                <h4>Custom Software Solutions</h4>
              </div>
            </div>
            <div className="featured-content">
              <div className="featured-desc pb-20">
                <p>We develop tailored software solutions that align perfectly with your business needs and goals.</p>
              </div>
              <Link className="ttm-btn btn-inline ttm-icon-btn-right ttm-btn-size-md ttm-btn-color-skincolor" to="/services/custom-software">Read more<i className="icon-right" /></Link>
            </div>
            <div className="bottom-icon-01">
              <i className="flaticon flaticon-padlock" />
            </div>
          </div>  
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="featured-icon-box">
            <div className="featured-thumbnail">
              <img height={390} className="img-fluid" src="/assets/images/service-img-01.html" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* service-section end */}
    <Technology />
  {/* cta-section start */}
  <section className="prt-row cta-section clearfix">
    <div className="container">
      <div className="row g-0">
        <div className="col-lg-4 col-md-12">
          {/* col-bg-img-one */}
          <div className="col-bg-img-two prt-bg z-index-11 prt-col-bgimage-yes prt-left-span">
            <div className="prt-col-wrapper-bg-layer prt-bg-layer">
              <div className="prt-bg-layer-inner" />
            </div>
          </div>
          {/* Responsive View image */}
          <img src="/assets/images/bg-image/col-bgimage-1.html" className="prt-equal-height-image" alt="col-bgimage-1" />
        </div>
        <div className="col-lg-8 col-md-12">
          <div className="bg-base-custom-dark prt-bg prt-col-bgcolor-yes z-index-1 position-relative prt-right-span spacing-12  ">
            <div className="prt-col-wrapper-bg-layer prt-bg-layer">
              <div className="prt-bg-layer-inner" />
            </div>
            <div className="layer-content res-375-pr-0">
              <div className>
                {/* section title */}
                <div className="section-title">
                  <div className="title-heading">
                    <div className="sub-title">
                      <span>why choose us</span>
                    </div>
                    <h2 className="title">Reasons To Choose Our IT Solutions &amp; Services</h2>
                  </div>
                </div>
                <div className="title-desc">
                  <p>Web designing in a powerful way of just not a profession, however, in a passion for our Company. We have a tendency to believe the idea that smart looking.</p>
                </div>
              </div>{/* section title end */}
              <div className="row">
                <div className="col-12">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style3 left-icon icon-align-top p-0 mb-0  res-991-pb-15">
                    <div className="featured-content-wrapper">
                      <div className="featured-icon d-flex align-items-center">{/* featured-icon */}
                        <div className="prt-icon prt-icon_element-border prt-icon_element-color-white prt-icon_element-size-md">
                          <i className="flaticon flaticon-process" />
                        </div>
                        <div className="featured-title">{/* featured title */}
                          <h5>Expertise</h5>
                        </div>
                      </div>
                    </div>
                    <div className="featured-content res-375-pl-15">
                      <div className="featured-desc-text">{/* featured desc */}
                        <p>With over a decade of experience, our team brings unparalleled expertise to every project</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* separator */}
              <div className="separator">
                <div className="sep-line mb-15 mt-15" />
              </div>
              {/* separator */}
              <div className="row">
                <div className="col-12">
                  {/* featured-icon-box */}
                  <div className="featured-icon-box style3 left-icon icon-align-top p-0 mb-0">
                    <div className="featured-content-wrapper">
                      <div className="featured-icon d-flex align-items-center">{/* featured-icon */}
                        <div className="prt-icon prt-icon_element-border prt-icon_element-color-white prt-icon_element-size-md">
                          <i className="flaticon flaticon-system" />
                        </div>
                        <div className="featured-title">{/* featured title */}
                          <h5>Customization</h5>
                        </div>
                      </div>
                      <div className="featured-content res-375-pl-15 res-991-pb-15">
                        <div className="featured-desc-text mb-0">{/* featured desc */}
                          <p>We have the technology expertise to develop solutions that can connect people and businesses across a variety of mobile devices..</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> 
  {/* cta-section end */}
  <section className="prt-row padding-zero-section clearfix"></section>
  
  {/*  padding-zero section start */}
  <section className="prt-row padding-zero-section clearfix">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="process-box01">
            <div className="feac-box d-flex">
              <div className="prt-fetured-content">
                <i className="flaticon flaticon-data" />
                <div className="fetured-desc mt-15">
                  <h3 className="featured-title-h3">Explore Services List</h3>
                </div> 
              </div>
              <div className="prt-processbox-wrapper box-2">
                <div className="process-num">
                  <span>01</span>   
                </div>
              </div>
            </div>
            <div className="fetured-desc"> 
              <p>Discover a wide range of tailored IT solutions designed to<br />meet your business needs.</p>
            </div>
          </div>
          <div className="process-box02">
            <div className="feac-box d-flex pl-100 pt-50 res-575-pl-0 res-991-pl-280">
              <div className="prt-fetured-content">
                <i className="flaticon flaticon-developer" />
                <div className="fetured-desc mt-15">
                  <h3 className="featured-title-h3">Request Meet Up</h3>
                </div> 
              </div>
              <div className="prt-processbox-wrapper box-2">
                <div className="process-num">
                  <span>02</span>   
                </div>
              </div>
            </div>
            <div className="fetured-desc pl-100 pb-50 res-1199-pl-280 res-480-pl-0"> 
              <p>Schedule a meeting with our expert team <br />to discuss your project goals.</p>
            </div>
          </div>
          <div className="process-box03 res-375-mb-30">
            <div className="feac-box d-flex">
              <div className="prt-fetured-content">
                <i className="flaticon flaticon-resume" />
                <div className="fetured-desc mt-15">
                  <h3 className="featured-title-h3">Initiate Your Project</h3>
                </div> 
              </div>
              <div className="prt-processbox-wrapper box-2">
                <div className="process-num">
                  <span>03</span>   
                </div>
              </div>
            </div>
            <div className="fetured-desc"> 
              <p>Kickstart your project by providing the necessary details,<br />and we’ll ensure a smooth and timely execution.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="section-title res-991-mt-30">
            <div className="title-heading">
              <div className="sub-title">
                <span>How It Work</span>
              </div>
              <h2 className="title">check out our work process</h2>
            </div>
          </div> 
          <div className="prt-featured-desc mb-40">
            <p>Our IT services converge business and technology experts to help to manage business processes of all categories.</p>
          </div>
          <div className="prt-single-image-wrapper">
            <img className="img-fluid lazyloaded" data-src="/assets/images/single-img-02.webp" alt="single-img-one" src="/assets/images/single-img-02.html" />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*  padding-zero section end */}
  
    </div>
  )
}

export default Home

import React from 'react'

function PaddingZero() {
  return (
 <div>
  
</div>

  )
}

export default PaddingZero

import React from 'react'
import ServiceAside from './ServiceAside';

function CustomSoftware() {
  return (
    <div>
  {/* page-title */}
  <div className="prt-page-title-row">
    <div className="container">
      <div className="row">
        <div className="col-md-12"> 
          <div className="title-box text-center">
            <div className="page-title-heading">
              <h1 className="title">Advisory Services</h1>
            </div>{/* /.page-title-captions */}
            <div className="breadcrumb-wrapper">
              <span>
                <a title="Homepage" href="/"><i className="ti ti-home" />Home</a>
              </span>
              <span className="prt-bread-sep">: :</span>
              <span>Custom Software Solutions</span>
            </div>  
          </div>
        </div>{/* /.col-md-12 */}  
      </div>{/* /.row */}  
    </div>{/* /.container */}                      
  </div>{/* page-title end*/}
  {/*site-main start*/}
  <div className="site-main">
    {/* sidebar */}
    <div className="sidebar prt-sidebar prt-bgcolor-white clearfix">
      <div className="container">
        {/* row */}
        <div className="row pb-90">
          <div className="col-lg-8 content-area order-lg-2 pt-65">
            {/* prt-service-single-content-are */}
            {/* prt-service-single-content-are */}
            <div className="prt-service-single-content-area ml-15 res-991-mr-15 res-767-ml-0">
              {/* section title */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-heading">
                  <div className="sub-title">
                    <span>We Build everything</span>
                  </div>
                  <h2 className="title">Custom Software Solutions</h2>
                </div>
              </div>{/* section title end */}
              <div className="ttm_single_image-wrapper mb-35">
                <img className="img-fluid" src="/assets/images/single-img-twelve.html" alt="single-img-twelve" />
              </div>
              <div className="prt-service-description">
                <h3>About Custom Software Solutions</h3>
                <p>At QuadBrains Technology, we specialize in delivering custom software solutions tailored to the unique needs of your business. Our approach goes beyond generic, off-the-shelf software by developing applications that are built specifically for your business goals, operational requirements, and industry standards.</p>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                </div>
                {/* separator */}
                <div className="row">
                  <div className="col-md-12">
                    <h3>Why Choose Us for Custom Software Solutions ?</h3>


{/* acadion */}
<div className="accordion style2 mt-20 mb-70">
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseFourtyOne">Tailored to Your Needs</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">We work closely with you to understand your business processes and challenges. Our solutions are fully customized to streamline workflows, improve efficiency, and address the specific pain points your organization faces.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapseFourtyTwo">Scalable and Future-Ready</a></div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">Our custom software solutions are designed to scale as your business grows. Whether you need to add more features, users, or integrations, our solutions are built to adapt to your evolving needs.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapseFourtyThree">End-to-End Development</a></div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">From initial consultation to design, development, testing, and deployment, we provide comprehensive support throughout the software development lifecycle. Our team ensures that your custom solution is delivered on time, within budget, and meets the highest quality standards.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapseFourtyFour">Seamless Integration</a></div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">We understand that your business relies on various systems and platforms. Our custom software is designed for seamless integration with your existing infrastructure, ensuring smooth operations across all platforms.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapseFourtyFive">Security and Compliance</a></div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">Security is at the forefront of our custom software development process. We implement robust security measures and ensure that all solutions comply with relevant industry regulations, protecting your data and maintaining business continuity.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
</div>{/* acadion end */}


                  
                   
                  </div>

                </div>
              
              </div>
            </div>
            {/* prt-service-single-content-are end */}
          </div>
          <div className="col-lg-4 widget-area">
          <ServiceAside />
            
          </div>
        </div>{/* row end */}
      </div>
    </div>
    {/* sidebar end */}
  </div>{/*site-main end*/}
</div>

  )
}

export default CustomSoftware

import React from 'react'

function portfolio() {
  return (
   <div>
  {/* portfolio-section start */}
  <section className="prt-row portfolio-section clearfix">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <div className="title-heading">
              <div className="sub-title text-center">
                <span>Learn our project</span>
              </div>
              <h2 className="title">Let Our Work Speak About Us</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="row box-layer g-0">
        <div className="col-lg-6">
          <div className="prt-video-wrapper">
            <div className="video-autoplay">
              <video className="img-fluid" loop autoPlay="autoplay" controls="controls" muted content="noindex">
                <source src="/assets/images/video.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="prt-about-content pl-25 res-991-pt-30">
            <h3 className="featured-title-h3">Best IT Solutions and Services company that makes business establishment essential for the people</h3>
            <p>Web designing in a powerful way of just not a profession, however, in a passion for our Company. We have a tendency to believe the idea that smart looking of any website.</p>
            <ul>
              <li><i className="fa fa-arrow-right" aria-hidden="true" /><span>Practice maturity in hardware</span></li>
              <li><i className="fa fa-arrow-right" aria-hidden="true" /><span>Complete software platform devlopment</span></li>
              <li><i className="fa fa-arrow-right" aria-hidden="true" /><span>Device nd field testing for electronics</span></li>
            </ul>
          </div>   
        </div>
      </div>
    </div>
  </section>
  {/* portfolio-section end */}
</div>

  )
}

export default portfolio

import React from 'react'
import {  Link } from 'react-router-dom';

function Footer() {
  return (
 <div>
  {/* footer start */}
  <footer className="footer widget-footer bg-base-black overflow-hidden clearfix">
    <div className="prt-row-wrapper-bg-layer prt-bg-layer position-relative" />
    <div className="first-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="title-feac">
              <h4>Envision Your Success</h4>
              <p>To empower businesses with innovative IT solutions that enhance efficiency, drive growth, and ensure success.</p>
            </div>
            <div className="featured-content-wrapper d-flex">
              <img width={42} height={42} className="img-fluid orange-icon" src="/assets/images/footer-iconimg.html" alt="footer-img" />
              <span>
              Thane, Maharashtra
              </span>
            </div>
            <ul className="social-icons">
            <li className="whatsapp-img"><a href="https://wa.me/+919326866949?text=Hello%20QuadBrains%20Technology!%20I%27m%20interested%20in%20learning%20more%20about%20your%20services." target='blank' rel="noopener" aria-label="facebook"><img src="/assets/images/Whatsapp-icon.svg" alt="image" /></a>
              </li>
              <li><a href="https://www.facebook.com/QuadBrainsTechnology/" target='blank' rel="noopener" aria-label="facebook"><i className="fontello icon-facebook" /></a>
              </li>
              <li className="twitter-img"><a href="https://x.com/i/flow/login?redirect_after_login=%2FQuadbrainsTech" target='blank' rel="noopener" aria-label="twitter"><img src="/assets/images/twitter-white.svg" alt="image" /></a>
              </li>
              <li><a href="https://www.instagram.com/quadbrainstechnology/" target='blank' rel="noopener" aria-label="instagram"><i className="fontello icon-instagram" /></a>
              </li>
              <li><a href="https://www.linkedin.com/company/quadbrains-technologies/posts/?feedView=all" target='blank' rel="noopener" aria-label="linkedin"><i className="fontello icon-linkedin" /></a>
              </li>
            </ul>
            {/* <form id="subscribe-form" className="newsletter-form comment-form-author1 pt-30" method="post" action="#" data-mailchimp="true">
              <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                <input type="email" name="email" placeholder="Email Address.." required className="username" />
                <input type="submit" defaultValue="submit Now" className="submit prt-btn prt-btn-size-md prt-btn-shape-square prt-btn-style-fill " />
              </div>
              <div id="subscribe-msg" />
            </form> */}
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6">
            <div className="title-feac">
              <h4>Quick Links</h4>
              <ul className="menu-footer-link">
                <li><Link to="/contact-us">Make Appoinment</Link></li>
                <li><Link to="/services/web-development">Our Services</Link></li>
             
                <li><Link to="/about-us">About Company</Link></li>
            
                <li><Link to="/">Our Business Growth</Link></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-4 col-6">
            <div className>
              <ul className="menu-footer-link mt-35">
              <li><Link to="/contact-us">Free Consultation</Link></li>
                
                <li><Link to="/services/web-development">Web Development</Link></li>
                <li><Link to="/services/mobile-app">Mobile App</Link></li>
                <li><Link to="/services/custom-software">Custom Software Solutions</Link></li>
               
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-4 col-6">
            <div className>
              <ul className="menu-footer-link mt-35">
              <li><Link to="/services/experience-design">Experience Design</Link></li>
                <li><Link to="/services/data-engineering">Data Engineering</Link></li>
                <li><Link to="/contact-us">Contact Us</Link></li>
              </ul>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12">
            <div className="footer-contact-form title-feac">
              <h4>Contact Us</h4>
              <form id="contactform" className="comment-form_2 contact_form clearfix" method="post" noValidate="novalidate">
                <div className="col-lg-12">
                  <div className="response" />
                </div>
                <p className="comment-form-author">
                  <input id="fname" className="username" name="name" type="text" defaultValue placeholder="Your name" required="required" />
                </p>
                <p className="comment-form-email">
                  <input id="email" className="email" name="email" type="text" defaultValue placeholder="Email (required)" required="required" />
                </p>
                <p className="comment-form-comment">
                  <input id="mname" className="username" name="name" type="text" defaultValue placeholder="Comment" required="required" />
                </p> 
                <p className="form-submit mt-30 mb-10">
                  <input type="submit" defaultValue="submit Request" id="submit" className="submit prt-btn prt-btn-size-md prt-btn-shape-square prt-btn-style-fill " />
                </p>
              </form>
            </div> 
          </div> */}
        </div>
      </div>
    </div>
    <div className="bottom-footer bottom-footer-border">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="copyright ">
              <div className="cpy-text">
                <p className="mb-0">Copyright © 2024 <a href="#">Quadbrains Technologies</a> &nbsp; All rights reserved. </p>
              </div>
              <ul className="prt-list footer-nav-menu">
                {/* <li><a href="#">Privacy policy </a></li>
                <li><a href="#">Terms and conditions</a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* footer end */}
</div>

  )
}

export default Footer

import React from 'react'
import ServiceAside from './ServiceAside';

function WebDevelopment() {
  return (
    <div>
  {/* page-title */}
  <div className="prt-page-title-row">
    <div className="container">
      <div className="row">
        <div className="col-md-12"> 
          <div className="title-box text-center">
            <div className="page-title-heading">
              <h1 className="title">Web Development</h1>
            </div>{/* /.page-title-captions */}
            <div className="breadcrumb-wrapper">
              <span>
                <a title="Homepage" href="/"><i className="ti ti-home" />Home</a>
              </span>
              <span className="prt-bread-sep">: :</span>
              <span> Web Development</span>
            </div>  
          </div>
        </div>{/* /.col-md-12 */}  
      </div>{/* /.row */}  
    </div>{/* /.container */}                      
  </div>
  {/* page-title end*/}
  {/*site-main start*/}
  <div className="site-main">
    {/* sidebar */}
    <div className="sidebar prt-sidebar prt-bgcolor-white clearfix">
      <div className="container">
        {/* row */}
        <div className="row">
          <div className="col-lg-8 content-area order-lg-2 pt-65">
            {/* prt-service-single-content-area */}
            <div className="prt-service-single-content-area  res-1199-mr-15">
              <div className="ttm_single_image-wrapper mb-35">
                <img className="img-fluid" src="/assets/images/single-img-twelve.html" alt="single-img-twelve" />
              </div>
              <div className="prt-service-description ">
                <h3> Web Development</h3>
                <p> We create robust, scalable, and visually appealing websites that cater to your business needs. Our expertise includes front-end and back-end development, CMS integration, ecommerce solutions, and more</p>
                <p>With over a decade of experience in web development, we understand the importance of a strong online presence. From design to deployment, we collaborate closely with our clients to create websites that not only look great but also function flawlessly on any device.</p>
                <p>Let us help you bring your vision to life and create a web solution that drives results for your business.</p>
                <div className="row no-gutters box-shadow mb-35 g-0">
                  <div className="col-md-4 col-sm-4">
                    {/*prt-fid*/}
                    <div className="prt-fid inside prt-fid-view-lefticon style5 res-375-pt-15 res-375-ml-15">
                      <div className="prt-fid-left">
                        <div className="prt-fid-icon-wrapper">
                          <i className="flaticon flaticon-global-1" />
                        </div>
                      </div>
                      <div className="prt-fid-contents text-left">
                        <h4 className="prt-fid-inner">
                          <span data-appear-animation="animateDigits" data-from={0} data-to={15} data-interval={2} data-before data-before-style="sup" data-after="+" data-after-style="sub">10</span><span className="ml-5">+</span>
                        </h4>
                        <h3 className="prt-fid-title">Years Experience</h3>
                      </div>
                    </div>{/* prt-fid end*/}
                  </div>
                  <div className="col-md-4 col-sm-4">
                    {/*prt-fid*/}
                    <div className="prt-fid inside prt-fid-view-lefticon style5 res-375-ml-15">
                      <div className="prt-fid-left">
                        <div className="prt-fid-icon-wrapper">
                          <i className="flaticon flaticon-padlock" />
                        </div>
                      </div>
                      <div className="prt-fid-contents text-left">
                        <h4 className="prt-fid-inner">
                          <span data-appear-animation="animateDigits" data-from={0} data-to={1490} data-interval={20} data-before data-before-style="sup" data-after="+" data-after-style="sub">149</span><span className="ml-5">+</span>
                        </h4>
                        <h3 className="prt-fid-title">Happy Clients</h3>
                      </div>
                    </div>{/* prt-fid end*/}
                  </div>
                  <div className="col-md-4 col-sm-4">
                    {/*prt-fid*/}
                    <div className="prt-fid inside prt-fid-view-lefticon style5 res-375-ml-15">
                      <div className="prt-fid-left">
                        <div className="prt-fid-icon-wrapper">
                          <i className="flaticon flaticon-developer" />
                        </div>
                      </div>
                      <div className="prt-fid-contents text-left">
                        <h4 className="prt-fid-inner">
                          <span data-appear-animation="animateDigits" data-from={0} data-to={815} data-interval={10} data-before data-before-style="sup" data-after="+" data-after-style="sub">20</span><span className="ml-5">+</span>
                        </h4>
                        <h3 className="prt-fid-title">New Customers</h3>
                      </div>
                    </div>{/* prt-fid end*/}
                  </div>
                </div>
                <p className="mb-35">We focus on using the latest web technologies like React.js, Angular, Node.js, ASP.NET, and Python to build websites that perform exceptionally well in both desktop and mobile environments.</p>
                <h3>Why Choose Us?</h3>
                <div className="row m-0 mt-35">
                  <div className="col-lg-12">
                    <div className="row box-shadow spacing-11">
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        <div className="ttm_single_image-wrapper">
                          <img className="img-fluid" src="/assets/images/single-img-thirteen.html" alt="single-img-thirteen" />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-12 col-xl-6">
                        <p className="mb-30 res-1199-mt-20">At QuadBrains Technology, we believe in creating web experiences that enhance customer engagement, increase conversions, and drive growth. Whether you’re a startup looking to establish your online presence or an enterprise needing a scalable solution, we are here to turn your vision into reality.

</p>
                        <ul className="prt-list prt-list-style-icon">
                          <li><i className="fa fa-check-circle prt-textcolor-skincolor" /><span className="prt-list-li-content">Expertise </span></li>
                          <li><i className="fa fa-check-circle prt-textcolor-skincolor" /><span className="prt-list-li-content">Customization</span></li>
                          <li><i className="fa fa-check-circle prt-textcolor-skincolor" /><span className="prt-list-li-content">Quality</span></li>
                          <li><i className="fa fa-check-circle prt-textcolor-skincolor" /><span className="prt-list-li-content">Support</span></li>
                          <li><i className="fa fa-check-circle prt-textcolor-skincolor" /><span className="prt-list-li-content">Innovation</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <h3 className="mt-35">Analyzing IT services</h3>
                <p>At QuadBrains Technology, our IT Services Analysis is designed to evaluate, optimize, and transform your IT infrastructure to meet the evolving demands of your business. We work closely with you to understand your current IT setup, identify inefficiencies, and recommend strategic improvements that drive operational efficiency and innovation.</p>
                {/* acadion */}
                <div className="accordion style2 mt-20 mb-70">
                  {/* toggle */}
                  <div className="toggle">
                    <div className="toggle-title">
                      <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">What sets QuadBrains Technology apart from other IT service providers?</a>
                    </div>
                    <div className="toggle-content">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="mb-0">At QuadBrains Technology, we combine over a decade of expertise with a personalized approach to IT solutions. Our team not only delivers high-quality services but also takes the time to understand your unique needs and goals. We focus on tailored solutions, innovative strategies, and exceptional client support, ensuring that we meet and exceed your expectations.</p>
                        </div>
                      </div>
                    </div>
                  </div>{/* toggle end */}
                  {/* toggle */}
                  <div className="toggle">
                    <div className="toggle-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapsetwo">How does QuadBrains Technology ensure the quality of its IT services?</a></div>
                    <div className="toggle-content">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="mb-0">We ensure quality through a rigorous process that includes thorough assessments, customized solutions, and continuous monitoring. Our team of experienced professionals applies industry best practices and cutting-edge technology to deliver solutions that are reliable, secure, and aligned with your business objectives.</p>
                        </div>
                      </div>
                    </div>
                  </div>{/* toggle end */}
                  {/* toggle */}
                  <div className="toggle">
                    <div className="toggle-title"><a data-toggle="collapse" data-parent="#accordion" href="#collapsethree"> What benefits can I expect from partnering with QuadBrains Technology?</a></div>
                    <div className="toggle-content">
                      <div className="row">
                        <div className="col-sm-12">
                          <p className="mb-0">By partnering with us, you gain access to a wealth of IT expertise and a dedicated team focused on your success. Expect improved operational efficiency, enhanced technology performance, proactive support, and strategic guidance that helps you stay ahead of the competition. Our commitment to excellence ensures that your IT infrastructure supports your business growth and innovation.</p>
                        </div>
                      </div>
                    </div>
                  </div>{/* toggle end */}
                </div>{/* acadion end*/}
              </div>
            </div>
            {/* prt-service-single-content-are end */}
          </div>
          <div className="col-lg-4 widget-area">
            <ServiceAside />
            
          </div>
        </div>{/* row end */}
      </div>
    </div>
    {/* sidebar end */}
  </div> {/*site-main end*/}
</div>

  )
}

export default WebDevelopment

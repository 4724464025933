import React, { useState, useEffect } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';

function TopHeader() {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname);

  useEffect(() => {
    // Update activeMenu based on the current path
    setActiveMenu(location.pathname);
  }, [location.pathname]);

  const handleMenuClick = (path) => {
    setActiveMenu(path);
  };

  return (
    <div>
      {/* header start */}
      <header id="masthead" className="header prt-header-style-01">
        <div className="top_bar prt-topbar-wrapper clearfix">
          <div className="container-fluid">
            <div className="row g-0">
              <div className="col-lg-12">
                <div className="top-header bg-base-skin" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="prt-topbar d-flex flex-row align-items-start justify-content-start">
                  <div className="top_bar_contact_item">
                    <div className="top_bar_icon"><i className="fontello icon-mail" /></div>
                    <a href="mailto:info@quadbrains.com">info@quadbrains.com</a>
                  </div>
                  <div className="top_bar_contact_item">
                    <div className="top_bar_icon"><i className="fontello icon-phone" /></div><a href="tel:+91 9326866949">+91-9326-866-949</a>
                  </div>
                  <div className="top_bar_contact_item top_bar_social ms-auto d-flex">
                    {/* <ul className="top-contact">
                      <li className="header-line"><i className="ti-timer" />Office Hour : 09:00am - 9:00pm</li>
                    </ul> */}
                    <ul className="social-icons">
                    <li>
    <a href="https://wa.me/+919326866949?text=Hello%20QuadBrains%20Technology!%20I%27m%20interested%20in%20learning%20more%20about%20your%20services." target='_blank' rel="noopener" aria-label="whatsapp">
        <i className="fontello icon-whatsapp"  style={{    color: "darkgreen",fontSize: "medium"}}/>
    </a>
</li>
                      <li><a href="https://www.facebook.com/QuadBrainsTechnology/" target='blank' rel="noopener" aria-label="facebook"><i className="fontello icon-facebook" /></a></li>
                      <li><a href="https://x.com/i/flow/login?redirect_after_login=%2FQuadbrainsTech" target='blank' rel="noopener" aria-label="twitter"><img src="/assets/images/twitter.svg" alt="image" /></a></li>
                      <li><a href="https://www.instagram.com/quadbrainstechnology/" target='blank' rel="noopener" aria-label="instagram"><i className="fontello icon-instagram" /></a></li>
                      <li><a href="https://www.linkedin.com/company/quadbrains-technologies/posts/?feedView=all" target='blank' rel="noopener" aria-label="linkedin"><i className="fontello icon-linkedin" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* site-header-menu */}
        <div id="site-header-menu" className="site-header-menu">
          <div className="site-header-menu-inner prt-stickable-header">
            <div className="container">
              <div className="row g-0">
                <div className="col-lg-12">
                  {/*site-navigation */}
                  <div className="site-navigation d-flex align-items-center justify-content-between bg-base-white">
                    <div className="site-branding-wrap">
                      {/* site-branding */}
                      <div className="site-branding pt-30">
                        <h1>
                          <a className="home-link" href="/" rel="home">
                            <img id="logo-img" height={30} width={200} className="img-fluid auto_size" src="/assets/images/logo.png" alt="logo-img" />
                          </a>
                        </h1>
                      </div>
                      {/* site-branding end */}
                    </div>
                    <div className="sitemenu-main d-flex align-items-center justify-content-between">
                      {/* menu-link */}
                      <div className="menu-link">
                        <div className="btn-show-menu-mobile menubar menubar--squeeze">
                          <span className="menubar-box">
                            <span className="menubar-inner" />
                          </span>
                        </div>
                        <nav className="main-menu menu-mobile" id="menu">
                          <ul className="menu">
                            <li
                              className={`mega-menu-item ${activeMenu === '/' ? 'active' : ''}`}
                            >
                              <a
                                href="/"
                                className="mega-menu-link"
                                onClick={() => handleMenuClick('/')}
                              >
                                Home
                              </a>
                            </li>
                            <li
                              className={`mega-menu-item ${activeMenu === '/about-us' ? 'active' : ''}`}
                            >
                              <NavLink
                                to="/about-us"
                                className="mega-menu-link"
                                onClick={() => handleMenuClick('/about-us')}
                              >
                                About Us
                              </NavLink>
                            </li>
                            <li
                              className={`mega-menu-item ${
                                activeMenu.startsWith('/services') ? 'active' : ''
                              }`}
                            >
                              <NavLink
                                to="#"
                                className="mega-menu-link"
                                
                              >
                                Services
                              </NavLink>
                              <ul className="mega-submenu">
                                <li>
                                  <a
                                    href="/services/web-development"
                                    onClick={() =>
                                      handleMenuClick('/services/web-development')
                                    }
                                  >
                                     Web Development
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/data-engineering"
                                    onClick={() =>
                                      handleMenuClick('/services/data-engineering')
                                    }
                                  >
                                    Data Engineering
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/mobile-app"
                                    onClick={() =>
                                      handleMenuClick('/services/MobileApp')
                                    }
                                  >
                                     Mobile App
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/custom-software"
                                    onClick={() =>
                                      handleMenuClick('/services/custom-software')
                                    }
                                  >
                                     Custom Software Solutions
                                  </a>
                                </li>
                                {/* <li>
                                  <a
                                    href="/services/app-development"
                                    onClick={() =>
                                      handleMenuClick('/services/AppDevelopment')
                                    }
                                  >
                                    App Development
                                  </a>
                                </li> */}
                                <li>
                                  <a
                                    href="/services/experience-design"
                                    onClick={() =>
                                      handleMenuClick('/services/experience-design')
                                    }
                                  >
                                    Experience Design
                                  </a>
                                </li>
                               
                              </ul>
                            </li>
                            <li
                              className={`mega-menu-item ${activeMenu === '/contact-us' ? 'active' : ''}`}
                            >
                              <NavLink
                                to="/contact-us"
                                className="mega-menu-link"
                                onClick={() => handleMenuClick('/contact-us')}
                              >
                                Contact Us
                              </NavLink>
                            </li>
                          </ul>
                        </nav>
                        {/* menu end */}
                      </div>
                      {/* menu-link end*/}
                      {/* <div className="header-btn">
                        <Link className="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border" to="/contact-us">Get a Quote</Link>
                      </div> */}
                    </div>
                  </div>{/* site-navigation end*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* site-header-menu end*/}
      </header>
      {/* header end */}
    </div>
  );
}

export default TopHeader;

import React from 'react'
import ServiceAside from './ServiceAside';

function AppDevelopment() {
  return (
    <div>
      <div>
  {/* page-title */}
  <div className="prt-page-title-row">
    <div className="container">
      <div className="row">
        <div className="col-md-12"> 
          <div className="title-box text-center">
            <div className="page-title-heading">
              <h1 className="title">Digital Services</h1>
            </div>{/* /.page-title-captions */}
            <div className="breadcrumb-wrapper">
              <span>
                <a title="Homepage" href="/"><i className="ti ti-home" />Home</a>
              </span>
              <span className="prt-bread-sep">: :</span>
              <span>App Development</span>
            </div>  
          </div>
        </div>{/* /.col-md-12 */}  
      </div>{/* /.row */}  
    </div>{/* /.container */}                      
  </div>{/* page-title end*/}
  {/*site-main start*/}
  <div className="site-main">
    {/* sidebar */}
    <div className="sidebar prt-sidebar prt-bgcolor-white clearfix">
      <div className="container">
        {/* row */}
        <div className="row">
          <div className="col-lg-8 content-area order-lg-2 pt-65">
            {/* prt-service-single-content-are */}
            {/* prt-service-single-content-are */}
            <div className="prt-service-single-content-area mr-15">
              {/* section title */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-heading">
                  <div className="sub-title">
                    <span>We Build everything</span>
                  </div>
                  <h2 className="title">App Development</h2>
                </div>
              </div>{/* section title end */}
              <div className="ttm_single_image-wrapper mb-35">
                <img className="img-fluid" src="/assets/images/single-img-twelve.html" alt="single-img-twelve" />
              </div>
              <div className="prt-service-description">
                {/* <h3>App Installation &amp; Cloud Services</h3> */}
                <p> Our team develops high performance mobile apps for iOS and Android platforms. We focus on user experience, functionality, and seamless performance to ensure your app stands out in the competitive market.</p>
                <div className="ttm_single_image-wrapper mb-35">
                  <img className="img-fluid" src="/assets/images/single-img-fourteen.html" alt="single-img-fourteen" />
                </div>
                <h3>Digital Data Transformation</h3>
                <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop.</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                </div>
                {/* separator */}
                <div className="row mb-70">
                  <div className="col-md-6">
                    <h3>Digital Planning</h3>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit, totam rem aperiam,<b>eaque ipsa quae ab illo inventore</b>veritatis etqo quasi architecto beatae vitae dicta sunt explicabo &amp; Nemo enimjq Letraset sheets containing Lorem Ipsum.</p>
                    <p>Omnis iste natus error sit voluptatem accusantium doloremque laudantium, but the majority suffered. sheets lorem ipsum.</p>
                    <a className="prt-btn prt-btn-size-sm prt-btn-shape-rounded prt-btn-style-border" href="contact-02.html">View More</a>
                  </div>
                  <div className="col-md-6">
                    <div className="ttm_single_image-wrapper res-767-mt-20">
                      <img className="img-fluid" src="/assets/images/single-img-fifteen.jpg" alt="single-img-fifteen" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* prt-service-single-content-are end */}
          </div>
          <div className="col-lg-4 widget-area">
          <ServiceAside />
          
           
          </div>
        </div>{/* row end */}
      </div>
    </div>
    {/* sidebar end */}
  </div>{/*site-main end*/}
</div>

    </div>
  )
}

export default AppDevelopment

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Aboutus from './components/Aboutus';
import WebDevelopment from './components/Services/WebDevelopment';
import AppDevelopment from './components/Services/AppDevelopment';
import MobileApp from './components/Services/MobileApp';
import CustomSoftware from './components/Services/CustomSoftware';
import ExperienceDesign from './components/Services/ExperienceDesign';
import DataEngineering from './components/Services/DataEngineering';
import ClientSection from './components/ClientSection';
import ContactUs from './components/ContactUs';
import CtaSection from './components/CtaSection';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './components/Home';
import PaddingZero from './components/PaddingZero';
import Portfolio from './components/portfolio';
import Revolution from './components/Revolution';

function App() {
  return (
    <Router>
      <div className="App">
        <Header/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about-us' element={<Aboutus />} />
          <Route path='/services/web-development' element={<WebDevelopment />} />
          <Route path='/services/app-development' element={<AppDevelopment />} />
          <Route path='/services/mobile-app' element={<MobileApp />} />
          <Route path='/services/custom-software' element={<CustomSoftware />} />
          <Route path='/services/experience-design' element={<ExperienceDesign />} />
          <Route path='/services/data-engineering' element={<DataEngineering />} />
          <Route path='/clients' element={<ClientSection />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/cta' element={<CtaSection />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/revolution' element={<Revolution />} />
          {/* <Route path='/services' element={<Service />} /> */}
          <Route path='/padding-zero' element={<PaddingZero />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;

import React from 'react'
import ServiceAside from './ServiceAside';

function MobileApp() {
  return (
    <div>
  {/* page-title */}
  <div className="prt-page-title-row">
    <div className="container">
      <div className="row">
        <div className="col-md-12"> 
          <div className="title-box text-center">
            <div className="page-title-heading">
              <h1 className="title">Advisory Services</h1>
            </div>{/* /.page-title-captions */}
            <div className="breadcrumb-wrapper">
              <span>
                <a title="Homepage" href="/"><i className="ti ti-home" />Home</a>
              </span>
              <span className="prt-bread-sep">: :</span>
              <span>Mobile App</span>
            </div>  
          </div>
        </div>{/* /.col-md-12 */}  
      </div>{/* /.row */}  
    </div>{/* /.container */}                      
  </div>{/* page-title end*/}
  {/*site-main start*/}
  <div className="site-main">
    {/* sidebar */}
    <div className="sidebar prt-sidebar prt-bgcolor-white clearfix">
      <div className="container">
        {/* row */}
        <div className="row pb-90">
          <div className="col-lg-8 content-area order-lg-2 pt-65">
            {/* prt-service-single-content-are */}
            {/* prt-service-single-content-are */}
            <div className="prt-service-single-content-area ml-15 res-991-mr-15 res-767-ml-0">
              {/* section title */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-heading">
                  <div className="sub-title">
                    <span>We Build everything</span>
                  </div>
                  <h2 className="title">Mobile App</h2>
                </div>
              </div>{/* section title end */}
              <div className="ttm_single_image-wrapper mb-35">
                <img className="img-fluid" src="/assets/images/single-img-twelve.html" alt="single-img-twelve" />
              </div>
              <div className="prt-service-description">
                <h3>About Mobile App</h3>
                <p>At QuadBrains Technology, we excel in creating cutting-edge mobile applications that elevate your business to new heights. Whether you're looking to create a brand-new app or enhance an existing one, our team of skilled developers is here to deliver high-performance, scalable solutions tailored to your specific needs.</p>
                {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                </div>
                {/* separator */}
                <div className="row">
                  <div className="col-md-12">
                    <h3>Why Choose Us for Mobile App Development?</h3>



{/* accordion */}
<div className="accordion style2 mt-20 mb-70">
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseOnes">Custom Solutions</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">We provide fully customized mobile app development for both Android and iOS platforms, ensuring your app meets your business goals and user needs.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwos">Cross-Platform Expertise</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">Using frameworks like React Native and Flutter, we build apps that work seamlessly across multiple platforms, allowing you to reach a broader audience while minimizing costs.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseThrees">User-Centric Design</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">We focus on delivering apps with engaging UI/UX that enhance user interaction, leading to higher retention and better user experiences.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour">Security and Performance</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">Our mobile apps are designed with a strong emphasis on security and performance, ensuring data protection and smooth functioning even under high traffic.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseFive">Seamless Integration</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">We build apps that can integrate easily with your existing systems, providing a unified experience across your digital ecosystem.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
  
  {/* toggle */}
  <div className="toggle">
    <div className="toggle-title">
      <a data-toggle="collapse" data-parent="#accordion" href="#collapseSix">From Concept to Launch</a>
    </div>
    <div className="toggle-content">
      <div className="row">
        <div className="col-sm-12">
          <p className="mb-0">From concept to launch, our team takes a collaborative approach to ensure your mobile app aligns perfectly with your vision, while delivering the functionality and innovation that today’s users demand.</p>
        </div>
      </div>
    </div>
  </div>{/* toggle end */}
</div>{/* accordion end */}

                  
                   
                  </div>

                </div>
              
              </div>
            </div>
            {/* prt-service-single-content-are end */}
          </div>
          <div className="col-lg-4 widget-area">
          <ServiceAside />
            
          </div>
        </div>{/* row end */}
      </div>
    </div>
    {/* sidebar end */}
  </div>{/*site-main end*/}
</div>

  )
}

export default MobileApp

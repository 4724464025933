import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function ServiceAside() {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <div>
      <aside className="widget widget-nav-menu res-375-ml-15 res-375-mr-15">
        <ul className="widget-menu">
          <li className={isActive('/services/web-development') ? 'active' : ''}>
            <a href="/services/web-development">Web Development</a>
          </li>
          <li className={isActive('/services/data-engineering') ? 'active' : ''}>
            <a href="/services/data-engineering">Data Engineering</a>
          </li>
          <li className={isActive('/services/mobile-app') ? 'active' : ''}>
            <a href="/services/mobile-app">Mobile App</a>
          </li>
          <li className={isActive('/services/custom-software') ? 'active' : ''}>
            <a href="/services/custom-software">Custom Software Solutions</a>
          </li>
          {/* <li className={isActive('/services/app-development') ? 'active' : ''}>
            <a href="/services/app-development">App Development</a>
          </li> */}
          <li className={isActive('/services/experience-design') ? 'active' : ''}>
            <a href="/services/experience-design">Experience Design</a>
          </li>
          
        </ul>
      </aside>
      <aside className="widget widget-text  res-375-ml-15 res-375-mr-15">
              <h3 className="widget-title">About Us</h3>
              <div className="prt-author-widget">
                <div className="author-widget_img">
                  <img className="author-img img-fluid" src="/assets/images/author-img.html" alt="author image" />
                </div>
                <h4 className="author-name">Quadbrains-author</h4>
                <p className="author-widget_text">Let Quadbrains Technology handle the technical aspects, so you can focus on what matters most -growing your business.</p>
              </div>
            </aside>
            <aside className="widget widget-download  res-375-ml-15 res-375-mr-15">
              <h3 className="widget-title">Company Profile</h3>
              <ul className="download">
                <li>
                  <i className="far fa-file-pdf-o" />
                  <a href="/assets/documents/Quadbrains_Technology.pdf" target='blank' title="Download">Quadbrains.pdf</a>
                </li>
               
              </ul>
            </aside>
            <aside className="widget widget_media_image">
              <div className="banner-img-box prt-textcolor-white text-left">
                <div className="featured-content featured-content-banner">
                  <i className="flaticon flaticon-call" />
                  <div className="featured-title prt-box-title">
                    <h5>How Can We Help?</h5>
                  </div>
                  <div className="featured-desc">
                    <p>If you need any help, please<br /> feel free to contact us.</p>
                  </div>
                  <ul>
                    <li><a href="tel:+91 9326866949">+91-9326-866-949</a></li>
                    <li><i className="fa fa-envelope-o" /><a  className='small-case'  href="mailto:info@quadbrains.com">info@quadbrains.com</a></li>
                  </ul>
                </div>
              </div>
            </aside>
    </div>
  );
}

export default ServiceAside;

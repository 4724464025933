import React from 'react'
import ServiceAside from './ServiceAside';

function ExperienceDesign() {
  return (
    <div>
  {/* page-title */}
  <div className="prt-page-title-row">
    <div className="container">
      <div className="row">
        <div className="col-md-12"> 
          <div className="title-box text-center">
            <div className="page-title-heading">
              <h1 className="title">Experience Design</h1>
            </div>{/* /.page-title-captions */}
            <div className="breadcrumb-wrapper">
              <span>
                <a title="Homepage" href="/"><i className="ti ti-home" />Home</a>
              </span>
              <span className="prt-bread-sep">: :</span>
              <span>Experience Design</span>
            </div>  
          </div>
        </div>{/* /.col-md-12 */}  
      </div>{/* /.row */}  
    </div>{/* /.container */}                      
  </div>{/* page-title end*/}
  {/*site-main start*/}
  <div className="site-main">
    {/* sidebar */}
    <div className="sidebar prt-sidebar prt-bgcolor-white clearfix">
      <div className="container">
        {/* row */}
        <div className="row">
          <div className="col-lg-8 content-area order-lg-2 pt-65">
            {/* prt-service-single-content-are */}
            {/* prt-service-single-content-are */}
            <div className="prt-service-single-content-area mr-15">
              {/* section title */}
              <div className="section-title with-desc text-left clearfix">
                <div className="title-heading">
                  <div className="sub-title">
                    <span>We Build everything</span>
                  </div>
                  <h2 className="title">Experience Design</h2>
                </div>
              </div>{/* section title end */}
              <div className="ttm_single_image-wrapper mb-35">
              <img className="img-fluid" src="/assets/images/single-img-fourteen.html" alt="single-img-fourteen" />
              </div>
              <div className="prt-service-description">
               
               <p></p>
               {/* separator */}
                <div className="separator">
                  <div className="sep-line mt-35 mb-35 res-991-mtb-20" />
                </div>
                {/* separator */}
                <div className="row">
  <div className="col-md-12">
    <h3>Key Features of Experience Design</h3>

    {/* accordion */}
    <div className="accordion style2 mt-20 mb-70">
      
      {/* toggle */}
      <div className="toggle">
        <div className="toggle-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseFifty">User-Centered Approach</a>
        </div>
        <div className="toggle-content">
          <div className="row">
            <div className="col-sm-12">
              <p className="mb-0">We place the user at the core of our design process. By understanding user behaviors, preferences, and pain points, we create solutions that are easy to use and deliver value seamlessly.</p>
            </div>
          </div>
        </div>
      </div>{/* toggle end */}
      
      {/* toggle */}
      <div className="toggle">
        <div className="toggle-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseFiftyOne">Research-Driven Insights</a>
        </div>
        <div className="toggle-content">
          <div className="row">
            <div className="col-sm-12">
              <p className="mb-0">Our design process begins with in-depth research, including user interviews, market analysis, and competitor reviews. These insights drive our design decisions, ensuring we create experiences that truly resonate with your audience.</p>
            </div>
          </div>
        </div>
      </div>{/* toggle end */}
      
      {/* toggle */}
      <div className="toggle">
        <div className="toggle-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseFiftyTwo">Intuitive Navigation</a>
        </div>
        <div className="toggle-content">
          <div className="row">
            <div className="col-sm-12">
              <p className="mb-0">A well-designed product is one that users can navigate effortlessly. We prioritize clarity and simplicity, making it easy for users to find what they need and complete their tasks with minimal friction.</p>
            </div>
          </div>
        </div>
      </div>{/* toggle end */}
      
      {/* toggle */}
      <div className="toggle">
        <div className="toggle-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseFiftyThree">Engaging User Interfaces (UI)</a>
        </div>
        <div className="toggle-content">
          <div className="row">
            <div className="col-sm-12">
              <p className="mb-0">We combine functionality with aesthetic appeal, designing interfaces that are not only visually attractive but also responsive and interactive. Our designs encourage user engagement and foster lasting connections.</p>
            </div>
          </div>
        </div>
      </div>{/* toggle end */}
      
      {/* toggle */}
      <div className="toggle">
        <div className="toggle-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseFiftyFour">Cross-Device Consistency</a>
        </div>
        <div className="toggle-content">
          <div className="row">
            <div className="col-sm-12">
              <p className="mb-0">Whether on mobile, desktop, or tablet, our experience design ensures consistency across all devices. This unified experience helps to build brand trust and ensures users can interact with your product seamlessly, regardless of platform.</p>
            </div>
          </div>
        </div>
      </div>{/* toggle end */}
      
      {/* toggle */}
      <div className="toggle">
        <div className="toggle-title">
          <a data-toggle="collapse" data-parent="#accordion" href="#collapseFiftyFive">Accessibility and Inclusivity</a>
        </div>
        <div className="toggle-content">
          <div className="row">
            <div className="col-sm-12">
              <p className="mb-0">We make sure our designs are accessible to all users, including those with disabilities. Our inclusive design approach ensures that no user is left behind, providing equal access to everyone.</p>
            </div>
          </div>
        </div>
      </div>{/* toggle end */}
    
    </div>{/* accordion end */}
  </div>
</div>

              </div>
            </div>
            {/* prt-service-single-content-are end */}
          </div>
          <div className="col-lg-4 widget-area">
          <ServiceAside />
           
            
          </div>
        </div>{/* row end */}
      </div>
    </div>
    {/* sidebar end */}
  </div>{/*site-main end*/}
</div>

  )
}

export default ExperienceDesign
